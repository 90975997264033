.leaflet-div-icon {
  border-radius: 50% 50% 50% 50%;
  background: #0065b3;
  width: 30px;
  text-align: center;
  color: white;
}

.margin-left-15 {
  margin-left: 15%;
}

.leaflet-div-icon-2 {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: white;
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}

.footer {
  background-color: #ffffff;
  text-align: center !important;
  width: 100% !important;
  height: 100px !important;
  color: rgb(0, 0, 0) !important;
  font-family: Quicksand, Roboto, sans-serif !important;
  position: absolute !important;
  left: 0;
  margin-top: 100px;
}

.login-form {
  height: auto;
  text-align: center;
  width: 100%;
  background-color: #fff;
  border-radius: 15px;
  margin-top: 1%;
}
.page-height {
  height: 100vh;
}

/* .ant-table-thead .ant-table-cell {
  background-color: #001528;
  color: white;
  text-align: center;
} */

.ant-table-row {
  text-align: center;
  align-items: center;
}

.custom-row-hover:hover td {
  background: whitesmoke !important;
  cursor: pointer !important;
}

.custom-row-hover td {
  align-items: left !important;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.ant-divider-horizontal.ant-divider-with-text-center::before,
.ant-divider-horizontal.ant-divider-with-text-center::after {
  border-top: 1px solid #31438e;
}

.ant-modal-header {
  background-color: #31438e;
  text-align: center;
}
.ant-modal-title {
  color: #fff;
}

.ant-card-head {
  background-color: #31438e !important;
  text-align: center;
}

.ant-card-head-title {
  color: #fff;
}

.leaflet-popup-content-wrapper {
  width: "500px";
}
.leaflet-popup-content {
  width: "500px";
}

.leaflet-popup-content-wrapper {
  width: max-content;
}

.div .body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.rsm-geographies .rsm-geography {
  width: 1000px;
  height: 1000px;
}

.ant-modal-close {
  color: #fff;
}

.ant-modal-close:hover {
  color: #31438e;
}

.full-viewport {
  /* height: 500px; */
}

.ant-popover-content {
  width: 300px;
  border-radius: 15px;
}
.existing-suspicious {
}

/* .ant-table-thead:hover:after  {
  background: #001528!important;
  color: white;
  background-color: #001528!important;
} */

/* .ant-table-thead {
  background: #001528!important;
  color: white;
  background-color: #001528!important;
}
.th.ant-table-column-sort {
  background: #001528!important;
  color: white;
  background-color: #001528!important;
}

.ant-table-column-sorters{
  background: #001528!important;
  color: white;
  background-color:#001528!important;
}

.ant-table-column-sorters:hover{
  background: #001528!important;
  color: white;
  background-color: #001528!important;
}

.ant-table-thead th.ant-table-column-sort{
  background: #001528!important;
  color: white;
  background-color: #001528!important;
}

.ant-menu.ant-menu-sub.ant-menu-vertical{
  background-color: #001528!important;
  width: min-content;
}
.ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {
  width: min-content!important;
  max-width: min-content!important;
} */

.chart-container {
  width: 600px; /* Adjust the width as per your requirements */
  height: 600px; /* Adjust the height as per your requirements */
}

.paragraph-container {
  max-width: 100%;
  overflow: hidden;
}

.paragraph-container p {
  margin: 0;
  word-wrap: break-word;
  text-align: justify;
}

.custom-row-hover:hover td {
  background: whitesmoke !important;
  cursor: pointer !important;
}
/* .ant-menu-light .ant-menu-item-selected:hover {
  background-color: #40a9ff; /* Change to your desired hover background color for selected item */
color: #fff; /* Change to your desired hover text color for selected item */
/* } */

.ant-menu-light .ant-menu-item {
  background-color: #050a30 !important; /* Set the background color of menu items */
  color: grey !important; /* Set the text color of menu items */
}
.ant-menu-light .ant-menu-item:hover {
  background-color: black !important; /* Set the hover background color of menu items */
  color: white !important; /* Set the hover text color of menu items */
}
.ant-menu-light .ant-menu-item-selected {
  background-color: #0074b7; /* Set the background color of selected menu items */
  color: white !important; /* Set the text color of selected menu items */
}

.custom-table .ant-table-thead > tr > th {
  background-color: rgb(
    62,
    65,
    103
  ); /* Change this to the desired background color */
  color: white; /* Change this to the desired text color */
  text-align: center;
}
.cardPage {
  transition: all 3s ease;
}
.custom-rowTrans td {
  height: 30px !important; /* Set the desired row height */
  padding: 5px; /* Optional: Adjust padding */
}
.apexcharts-legend {
  height: 300px !important;
  margin-bottom: 20px;
}
.apexcharts-legend-text {
  float: left !important;
}

.responsive-div {
  /* width: 50vw;  */
  /* 50% of the viewport width */
  /* z-index: 10; */
  /* height: 100vh; */
}

/* .responsive-div {
   width: 50vw;
  height: 30vh;
  background-color: blue;  
} */

@media (max-width: 600px) {
  .responsive-div {
    min-width: 90vw;
    /* height: 100vh; */
    /* background-color: red; */
  }
  .mapSize {
    height: "500px" !important;
    width: "500px" !important;
  }
  .clusterImage {
    display: none;
  }
}

@media (min-width: 601px) and (max-width: 1280px) {
  .responsive-div {
    /* height: 100vh; */
    min-width: 180px;
    /* background-color: green; */
  }
  .existing-suspicious {
    height: 400px;
  }
  .etdSearch {
    height: 400px;
  }

  .mapSize {
    height: "500px" !important;
    width: "500px" !important;
  }
  .empytHeight {
    /* height: 100vh; */
    height: 10px !important;
  }
  .socialNetWork {
    height: 500px !important;
    width: 900px !important;
  }
  .empytHeightAID {
    /* height: 100vh; */
    height: 10px !important;
  }

  .commentsHeight {
    height: 410px !important;
  }
  .transactionsHeight {
    height: 370px !important;
  }
  .pageAnalysisSizes {
    height: 400px !important;
  }
  .pageAnalysisSizesNer {
    height: 360px !important;
  }
  .clusterSizes {
    height: 300px !important;
    width: 300px !important;
  }
  .clusterImage {
    display: none;
  }
}

@media (min-width: 1281px) and (max-width: 1920px) {
  .responsive-div {
    /* height: 100vh; */
    min-width: 220px !important;

    /* background-color: pink; */
  }
  .existing-suspicious {
    height: 500px;
  }
  .etdSearch {
    height: 400px;
  }
  .mapSize {
    height: "700px" !important;
    width: "700px" !important;
  }
  .empytHeight {
    /* height: 100vh; */
    height: 10px !important;
  }
  .empytHeightAID {
    /* height: 100vh; */
    height: 10px !important;
  }
  .socialNetWork {
    height: 500px !important;
    width: 900px !important;
  }

  .commentsHeight {
    height: 410px !important;
  }
  .transactionsHeight {
    height: 370px !important;
  }
  .pageAnalysisSizes {
    height: 400px !important;
  }
  .pageAnalysisSizesNer {
    height: 360px !important;
  }
  .clusterSizes {
    height: 440px !important;
    width: 360px !important;
  }
  .clusterImage {
    display: none;
  }
}

@media (min-width: 1921px) and (max-width: 2560px) {
  .responsive-div {
    /* height: 100vh; */
    min-width: 320px !important;
    /* background-color: purple; */
  }
  .existing-suspicious {
    height: 1000px;
  }
  .mapSize {
    height: "800px" !important;
    width: "800px" !important;
  }

  .empytHeight {
    /* height: 100vh; */
    height: 500px !important;
  }
  .empytHeightAID {
    /* height: 100vh; */
    height: 300px !important;
  }
  .etdSearch {
    height: 700px;
  }
  .socialNetWork {
    height: 600px !important;
    width: 1200px !important;
  }
  .commentsHeight {
    height: 670px !important;
  }
  .transactionsHeight {
    height: 620px !important;
  }
  .pageAnalysisSizes {
    height: 700px !important;
  }
  .pageAnalysisSizesNer {
    height: 660px !important;
  }
  .clusterSizes {
    height: 640px !important;
    width: 500px !important;
  }
  .clusterImage {
    display: none;
  }
}

@media (min-width: 2561px) {
  .responsive-div {
    /* height: 100vh; */
    min-width: 400px !important;
    /* background-color: rgb(255, 214, 33); */
  }
  .empytHeight {
    /* height: 100vh; */
    height: 500px !important;
  }
  .empytHeightAID {
    /* height: 100vh; */
    height: 300px !important;
  }
  .socialNetWork {
    height: 1000px !important;
    width: 1200px !important;
  }
  .commentsHeight {
    height: 670px !important;
  }
  .transactionsHeight {
    height: 620px !important;
  }
  .pageAnalysisSizes {
    height: 700px !important;
  }
  .pageAnalysisSizesNer {
    height: 660px !important;
  }
  .clusterSizes {
    height: 640px !important;
    width: 500px !important;
  }
  .clusterImage {
    display: none;
  }
}

@media print {
  body {
    -webkit-print-color-adjust: exact; /* Chrome, Safari */
    color-adjust: exact; /* Firefox */
  }
  .printableTranslation {
    height: auto !important; /* Remove the fixed height */
    overflow: visible !important; /* Make content fully visible */
  }

  .addRecipeButton {
    display: none;
  }

  #transactionsTab .ant-tabs-nav {
    display: none;
  }

  #tasTab .ant-tabs-nav {
    display: none;
  }

  #tasTabHistory .ant-tabs-nav {
    display: none;
  }

  .showOnPrint {
    display: flex !important;
    margin-top: -40px !important;
    margin-bottom: 20px !important;
  }

  .showOnPrintAllHeight {
    height: auto !important;
    /* max-height: 2000px !important; */
    overflow-y: unset !important;
  }

  .mapsOnPrint {
    max-height: 100% !important;
    max-width: 100% !important;
  }
  .nextPagePrint {
    margin-bottom: 200px;
  }
  .socialNetWork {
    display: block;
  }
  .clusterSizes {
    height: 460px !important;
    width: 440px !important;
  }
  .clusterImage {
    display: block;
  }
  .clusterImageSocial {
    margin-top: 700px !important;
  }
}
