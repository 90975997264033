@keyframes blink {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.blinking-dots {
  display: inline-block;
}

.blinking-dots svg {
  animation: blink 1s infinite;
}
